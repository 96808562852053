@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  @media (--viewportMedium) {
    position: fixed;
    top: 0;
    left: 0;
    /* We need to raise Topbar above .container */
    z-index: 10;
  }
}

.container {
  width: 100%;
  margin-top: 138px;
  margin-bottom: 165px;

  @media (max-width: 1700px) {
    padding: 0 80px;
  }

  @media (max-width: 768px) {
    margin-top: 34px;
  }

  @media (max-width: 1024px) {
    padding: 0 24px !important;
  }

  @media (max-width: 1440px) {
    padding: 0 40px;
  }

  @media (min-width: 1700px) {
    margin-left: auto;
    margin-right: auto;
    max-width: 1531px;
    padding: 0;
  }
}

.searchRow {
  display: grid;
  grid-template-columns: 48% 52%;
  gap: 75px;
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 18px 0 0 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
    padding: 23px 0 0 0;
  }
}

.error {
  color: var(--failColor);
  padding-left: 24px;
  padding-right: 24px;
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.searchFiltersPrimary {
  /* display: none;

  @media (--viewportMedium) {
    display: flex;
    padding: 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 0 36px;
  } */
}

.searchFiltersMobile {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  /* padding: 0 24px 24px 24px; */

  @media (--viewportLarge) {
    /* padding: 0 36px 36px 36px; */
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
  margin-top: 100px;
}

.mainWrapper {
  background-color: #fff;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.sortBy {
  margin-right: 9px;
}

.sortByMenuLabel {
  composes: button marketplaceTinyFontStyles buttonText buttonBordersSecondary buttonColorsSecondary from global;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightMedium);

  height: 35px;
  min-height: 35px;
  padding: 0 18px;
  margin: 0;
  border-radius: 4px;
  white-space: nowrap;
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  /* width: 100vw; */
  height: 100vh;

  & .canvas {
    width: 100%;
  }
}

.mapBox {
  position: relative;

  & .searchInput {
    position: absolute;
    z-index: 9;
    width: 628px;
    margin: auto;
    left: 0;
    right: 0;
    top: 35px;
    background: #ffffff;
    border: 0.5px solid #939393;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%),
      0px 1px 8px rgb(0 0 0 / 20%);
    border-radius: 4px;
  }
}

.ais-InstantSearch__root {
  display: flex;
  flex-direction: column;
}

.ais-SearchBox {
  margin: 10px 0;
}

.ais-GeoSearch {
  padding-right: 0;
  padding-left: 0;
  height: 700px;
}

.marker {
  transform: translate(-50%, -100%) scale(0.5, 0.5);
}

.notLoginUserSearch {
  /* margin-top: 50px; */
}

.aisInstantSearch {
  display: flex;
  gap: 74px;

  & .fullLeftPanel {
    width: 100% !important;

    & :global(.ais-ClearRefinements-button) {
      padding: 13px 20px;
      cursor: pointer;
    }

    & :global(.ais-InfiniteHits-loadMore) {
      margin: 117px auto 0;
      display: block;
      color: #000;
      text-align: center;
      font-family: Lora;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.9px;
      text-decoration-line: underline;
      background: transparent;
      opacity: unset;
      position: relative;
      cursor: pointer;

      &:after {
        position: absolute;
        content: '';
        height: 10px;
        width: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='10' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L9 9L17 1' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        top: 13px;
        right: -29px;
      }
    }

    & :global(.ais-InfiniteHits-loadMore--disabled) {
      opacity: 0.6;
      cursor: not-allowed;
    }
  }

  & .leftPanel {
    width: 50%;
    padding-top: 35px;

    @media (max-width: 768px) {
      padding: 0;
    }

    @media (max-width: 1300px) {
      width: 100%;
    }

    & .dividerLine {
      background-color: #e0e0e0;
      height: 1px;
      width: 100%;
      margin: 0px 0 28px;
    }

    & .paginatgionBottom {
      margin-top: 57px;
    }

    & .searchFilter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 35px;
      flex-wrap: wrap;

      @media (max-width: 768px) {
        margin-bottom: 20px;
      }

      & .leftSearchFiler {
        display: flex;
        align-items: center;
        gap: 20px;
        flex-wrap: wrap;
      }

      & > div > div {
        &:nth-child(1) {
          @media (max-width: 460px) {
            width: 75%;
          }
        }

        &:nth-child(2) {
          @media (max-width: 600px) {
            /* width: 75%; */
          }
        }
      }

      & form {
        & button {
          &:nth-child(2) {
            left: 1.3rem;

            & svg {
              width: 21px;
              height: 21px;

              & path {
                fill: #23263b;
              }
            }
          }
        }

        & input {
          background: #ffffff;
          height: 51px;
          border: 0.5px solid #939393;
          border-radius: 4px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #939393;
          width: 362px;
          padding: 14px 14px 14px 57px;

          @media (max-width: 460px) {
            width: 100%;
          }
        }
      }

      & .filterBox {
        cursor: pointer;
      }

      & .dateSelect {
        & select {
          border: 0.5px solid #939393;
          border-radius: 4px;
          width: 289px;
          height: 51px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #939393;
          padding: 14px;
        }
      }
    }

    & .fullCardGrid {
      & ul {
        grid-template-columns: repeat(4, 1fr) !important;

        @media (max-width: 1200px) {
          grid-template-columns: repeat(3, 1fr) !important;
        }

        @media (max-width: 800px) {
          grid-template-columns: repeat(2, 1fr) !important;
        }

        @media (max-width: 500px) {
          grid-template-columns: repeat(1, 1fr) !important;
        }
      }
    }

    & .cardsGrid {
      & ul {
        display: grid;
        column-gap: 77px;
        row-gap: 30px;
        grid-template-columns: repeat(2, 1fr);
        margin: 0;

        @media (max-width: 1300px) {
          column-gap: 64px;
          grid-template-columns: repeat(4, 1fr);
        }

        @media (max-width: 1200px) {
          grid-template-columns: repeat(3, 1fr) !important;
        }

        @media (max-width: 800px) {
          grid-template-columns: repeat(2, 1fr) !important;
        }

        @media (max-width: 500px) {
          grid-template-columns: repeat(1, 1fr) !important;
        }

        @media (max-width: 1500px) {
          column-gap: 34px;
          margin: 0;
        }

        & li {
          width: 50%;
          min-width: 100%;
          margin: 0;
          border: none;
          box-shadow: none;
          padding: 0;
          cursor: pointer;
          display: flex;
          flex-direction: column;

          & > div {
            flex: 1;
          }
        }
      }
    }

    & .clearFilter {
      margin: 10px 0;
    }

    & .selectCategoryData {
      display: flex;

      & :global(.ais-RefinementList-item) {
        background: #ffffff;
        border: 1px solid #212121;
        padding: 8px 20px;
        border-radius: 100px;
        margin-right: 15px;
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px;
        /* 271.429% */
        color: #212121;
        min-width: fit-content;
        cursor: pointer;
        width: max-content;

        @media (max-width: 767px) {
          padding: 8px 12px;
        }

        & label {
          margin-bottom: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          & span {
            &:nth-child(3) {
              border-radius: 50%;
              width: 20px;
              height: 20px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 10px;
            }
          }
        }
      }

      & :global(.ais-RefinementList) {
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        max-width: 90%;
        margin-bottom: 34px;

        &::-webkit-scrollbar {
          height: 6px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f1;
        }

        &::-webkit-scrollbar-thumb {
          background: #888;
        }
      }

      & ul {
        display: flex;
        flex-direction: row;

        -webkit-box-flex: 1;
        flex-grow: 1;
        padding-bottom: 10px;
      }

      & :global(.ais-RefinementList-item--selected) {
        background: #fff8f2;
        border: 1px solid #4661ed;
        border-radius: 100px;
        transform: matrix(1, 0, 0, 1, 0, 0);

        & label {
          & span {
            color: #4661ed;
            font-weight: 500;
          }
        }
      }

      & li {
        background: #ffffff;
        border: 1px solid #212121;
        padding: 8px 20px;
        border-radius: 100px;
        margin-right: 15px;
        text-align: center;
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 38px;
        /* 271.429% */
        color: #212121;
        min-width: fit-content;
        cursor: pointer;

        & label {
          margin-bottom: 0 !important;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          & span {
            white-space: nowrap;

            &:nth-child(3) {
              border-radius: 50%;
              width: 20px;
              height: 20px;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  & .rightPanel {
    width: 50%;
    position: sticky;
    top: 120px;
    height: calc(100vh - 120px);

    @media (max-width: 1300px) {
      display: none;
    }

    & .mapRightBar {
      height: calc(100vh - 120px);

      & > div {
        height: calc(100vh - 120px);

        & > div {
          & > div {
            & > div {
              & > div {
                &:nth-child(8) {
                  & > div {
                    height: calc(100vh - 120px);
                    right: 48px;
                    left: unset !important;
                    bottom: 98px;
                    top: unset !important;

                    & > div {
                      & > div {
                        box-shadow: 0px 4px 8px rgba(35, 38, 59, 0.25) !important;
                        border-radius: 8px !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    & .rangeSliderBox {
      position: absolute;
      left: 43px;
      margin-right: auto;
      width: 575px;
      z-index: 9;
      bottom: 35px;
      background: #ffffff;
      box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%),
        0px 1px 8px rgb(0 0 0 / 20%);
      border-radius: 8px;
      padding: 22px 30px;

      & .sliderHead {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        & .radioName {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.15px;
          color: #212121;
        }
      }
    }

    & .searchMapInput {
      position: absolute;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      width: 628px;
      z-index: 8;
      top: 35px;

      @media (max-width: 1500px) {
        width: 70%;
      }

      & form {
        display: flex;
        & :global(.aa-InputWrapperPrefix) {
          display: flex;
          align-items: center;
          background-color: #fff;
          & button {
            border: 0;
          }
          & label {
            margin: 0 !important;
          }
        }
        & :global(.aa-InputWrapper) {
          flex: 1;
        }
        & button {
          &:nth-child(2) {
            left: 1.3rem;
            background-image: url(../../assets/search-location.png);
            background-size: 17px;
            background-repeat: no-repeat;
            background-position: center;
            height: 30px;

            & svg {
              /* width: 21px;
              height: 21px; */
              display: none;

              & path {
                fill: #23263b;
              }
            }
          }
        }

        & input {
          height: 51px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.25px;
          color: #939393;
          padding: 14px 14px 14px 57px;
          background: #ffffff;
          border: 0.5px solid #939393;
          box-shadow: 0px 3px 4px rgb(0 0 0 / 14%), 0px 3px 3px rgb(0 0 0 / 12%),
            0px 1px 8px rgb(0 0 0 / 20%);
          border-radius: 4px;
        }
      }
    }
  }
}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  top: 2px;
  left: 4px;
  transition: transform 0.3s ease;
  background-color: #ffffff;

  @media (max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }

  &.toggled {
    transform: translateX(63%);
  }
}

.searchBar {
  z-index: 9;
  position: relative;
  background-color: #fff;
  display: flex;
  align-items: center;
  border-radius: 80px;
  justify-content: space-between;
  border-radius: 80px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  @media (max-width: 920px) {
    background-color: #fff0;
    flex-wrap: wrap;
    gap: 40px;
  }

  @media (max-width: 767px) {
    gap: 20px;
  }

  & .searchWrapper {
    width: 100%;
    display: flex;
  }

  & .searchLeft {
    display: flex;
    align-items: center;
    gap: 28px;
    width: 100%;
    position: relative;

    @media (max-width: 920px) {
      flex-wrap: wrap;
    }

    @media (max-width: 767px) {
      gap: 20px;
    }

    & .locationDropDown {
      position: relative;
      max-width: 250px;
      @media (max-width: 920px) {
        width: 100%;
        max-width: 100%;
      }

      & :global(.ais-MenuSelect) {
        background-image: url("data:image/svg+xml,%3Csvg class='LocationAutocompleteInput_iconSvg__uTJlN' width='26' height='27' viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.1109 23.4C12.5156 20.3906 18 13.0969 18 9C18 4.03125 13.9688 0 9 0C4.03125 0 0 4.03125 0 9C0 13.0969 5.48438 20.3906 7.88906 23.4C8.46562 24.1172 9.53438 24.1172 10.1109 23.4ZM9 12C7.34531 12 6 10.6547 6 9C6 7.34531 7.34531 6 9 6C10.6547 6 12 7.34531 12 9C12 10.6547 10.6547 12 9 12Z' fill='black'%3E%3C/path%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: 27px 47%;
        background-size: 20px;
      }

      & :global(.ais-MenuSelect-select) {
        border: 0;
        background-size: 19px;
        margin-left: 28px;
        padding-right: 10px;
        background-image: url("data:image/svg+xml, %3Csvg width='18.234' height='17.921' viewBox='0 0 26 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.69708 1.28925L13.0672 13.9353L24.399 1.25482' fill='none' stroke='%23111010' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' /%3E%3C/svg%3E");
      }

      & .downArrow {
        position: absolute;
        right: 13px;
        top: 32px;

        @media (max-width: 1440px) {
          right: 20px;
          top: 22px;
        }

        &.open {
          transform: rotate(178deg);
        }

        &.close {
        }
      }
    }
  }

  & .searchForm {
    width: 250px;
    height: 70px;
    background: #ffffff;
    box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 80px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 20px;

    @media (max-width: 1440px) {
      height: 80px;
    }

    @media (max-width: 920px) {
      height: 51px;
      width: 100%;
    }

    & > div {
      padding-left: 28px;

      @media (max-width: 920px) {
        width: 100%;
      }
    }

    & input {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 38px;
      letter-spacing: 0.15em;
      color: #111010;

      @media (max-width: 920px) {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }

  & .keyWordBox {
    position: relative;
    width: 100%;
    display: flex;

    & input ~ div {
      display: none;
    }

    & .searchIcon {
      position: absolute;
      left: 0;
      top: 8px;

      @media (max-width: 920px) {
        top: 12px;
        left: 20px;
      }

      & svg {
        width: 25px;
        height: 25px;

        @media (max-width: 1440px) {
          width: 25px;
          height: 25px;
        }
        @media (max-width: 920px) {
          width: 18px;
          height: 18px;
        }
      }
    }

    & .searchKeyword {
      border: none;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: 0.12em;
      padding: 14px 10px 14px 30px;

      @media (max-width: 1440px) {
        width: 100%;
      }

      @media (max-width: 920px) {
        background: #ffffff;
        border-radius: 80px;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 51px;
        font-size: 16px;
        line-height: 18px;
        padding: 14px 10px 14px 50px;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  & .goButton {
    background: #ffb169;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 100px;
    padding: 0px;
    color: #fff;
    text-align: center;
    font-family: Lora;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px; /* 211.111% */
    letter-spacing: 2.7px;
    margin-right: 3px;
    border: 1px solid #ffb169;
    cursor: pointer;
    width: max-content;
    height: 62px;
    width: 62px;
    min-width: 62px;

    &:hover {
      background-color: var(--primaryButtonColor);
      border: 1px solid var(--primaryButtonColor);
    }

    @media (max-width: 1440px) {
      line-height: 14px;
    }

    @media (max-width: 920px) {
      width: 100%;
      height: 51px;
    }
  }

  & .goFullButton {
    width: 100%;
    height: 71px;
    background: #ffb169;
    border: 1px solid #ffb169;
    border-radius: 100px;
    font-weight: 700;
    font-size: 20px;
    line-height: 38px;
    color: #fff;

    &:hover {
      background-color: var(--primaryButtonColor);
      border: 1px solid var(--primaryButtonColor);
    }
  }
}

.mainCover {
  width: 70%;

  z-index: 3;
  max-width: 683px;

  @media (max-width: 1440px) {
    width: 90%;
  }

  @media (max-width: 920px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    z-index: 6;
  }
  @media (max-width: 560px) {
    width: 100%;
  }

  & h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 38px;
    text-align: center;
    letter-spacing: 0.05em;
    color: #ffffff;
    margin-bottom: 73px;
    margin-top: 0;
  }
}

.categoriesList {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  max-width: 98%;
  &::-webkit-scrollbar {
    height: 7px;
    border-radius: 0;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.categoryItem {
  margin: 0px 20px 0 0;
  white-space: nowrap;
  color: var(--Paragraph, #595858);
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
  letter-spacing: 0.15px;
}

.selected {
  color: #4661ed !important;
}

.selectedSubcategry {
  display: flex;
  align-items: center;
  gap: 24px;
  color: unset;
  padding: 5px 0 5px;
  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    min-width: 80%;
    width: 100%;
  }

  @media (max-width: 560px) {
    min-width: 100%;
    width: 100%;
    gap: 10px;
  }

  & .noImage {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c5c4c4;
    width: 72px;
    min-width: 72px;
    height: 72px;
    border-radius: 50%;
    flex-wrap: wrap;
    font-size: 12px;
  }

  & .categoriesImage {
    & .logoBrand {
      border-radius: 50%;

      & > div {
        width: 72px;
        height: 72px;
        border-radius: 50%;

        & img {
          border-radius: 50%;
        }
      }
    }
  }

  & .categoriesText {
    & h4 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      font-family: 'Avenir LT Std', sans-serif;
      letter-spacing: 0.15px;
      color:  #4661ed;
      margin: 0;

      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }

    & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #595858;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.heroCategories {
  display: flex;
  align-items: center;
  gap: 24px;
  color: unset;
cursor: pointer;
  &:hover {
    text-decoration: none;
  }

  @media (max-width: 1024px) {
    min-width: 80%;
    width: 100%;
  }

  @media (max-width: 560px) {
    min-width: 100%;
    width: 100%;
    gap: 10px;
  }

  & .noImage {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c5c4c4;
    width: 72px;
    min-width: 72px;
    height: 72px;
    border-radius: 50%;
    flex-wrap: wrap;
    font-size: 12px;
  }

  & .categoriesImage {
    & .logoBrand {
      border-radius: 50%;

      & > div {
        width: 72px;
        height: 72px;
        border-radius: 50%;

        & img {
          border-radius: 50%;
        }
      }
    }
  }

  & .categoriesText {
    & h4 {
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      font-family: 'Avenir LT Std', sans-serif;
      letter-spacing: 0.15px;
      color: #212121;
      margin: 0;

      @media (max-width: 1024px) {
        font-size: 16px;
      }
    }

    & p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
      color: #595858;
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
}

.active{
  & * {
    color: #4661ED !important;
  }
}

.heroCategoriesBox {
  /* display: grid; */
  grid-template-columns: repeat(4, 1fr);
  gap: 39px;
  margin-top: 30px;
  &.fullHeroCategoriesBox {
    grid-template-columns: repeat(2, 1fr);
    gap: 29px;
    margin-top: 30px;
  }
  @media (max-width: 1440px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: row;
    /* overflow-x: scroll;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1; */
    padding-bottom: 10px;
  }
  & :global(.slick-list) {
    max-width: 1531px;
    width: 100%;
    @media (max-width: 1024px) {
      max-width: calc(100vw - 100px);
    }
  }
  & :global(.slick-arrow) {
    top: 40% !important;
  }
  & :global(.slick-prev) {
    @media (max-width: 1024px) {
      left: 0 !important;
      z-index: 7;
    }
  }
  /* 
  &::-webkit-scrollbar {
    display: none;
  }

  & {
    -ms-overflow-style: none;
    scrollbar-width: none;
  } */
}

.mapToogleButton {
  display: flex;
  gap: 20px;
  align-items: center;

  @media (max-width: 1300px) {
    display: none;
  }

  & .mapLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: #595858;
  }
}

.checkboxWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 64px;
  height: 39px;
  border: 1px solid #000000;
  background: rgb(0 0 0);

  @media (max-width: 768px) {
    /* height: 40px; */
    .breadcrumbsBox {
      & > div {
        display: flex;
        flex-wrap: wrap;
        row-gap: 5px;
      }
    }
  }
}

.navLinksBox {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 16px;
  margin-top: 29px;

  & nav {
    & > div {
      &:nth-child(7) {
        display: none;
      }

      &:nth-child(6) {
        display: none;
      }

      &:nth-child(5) {
        display: none;
      }
    }

    /* background-color: red; */
    &::-webkit-scrollbar {
      height: 7px;
      border-radius: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  & a {
    width: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;

    & > span {
      padding-left: 0;
    }
  }
}

.searchTabs {
  padding-bottom: 26px;
  margin-top: 15px;
  display: flex;

  @media (max-width: 768px) {
    border: none;
    padding-bottom: 16px;
    margin: 0;
  }

  & ul {
    display: flex;
    flex-wrap: wrap;

    & :global(.ais-div-item--selected) {
      & span {
        font-weight: 700;
        color: #4661ed;
      }
    }

    & li {
      margin-right: 20px;

      & label {
        font-weight: 400;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: #595858;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 10px;
        cursor: pointer;
      }

      & span {
        &:nth-child(3) {
          border-radius: 50%;
          width: 20px;
          height: 20px;
          position: relative;
          display: flex;
          display: none;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }

  & nav {
    @media (max-width: 768px) {
      flex-wrap: wrap;
    }

    & > div {
      @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 15px;
      }
    }

    &::-webkit-scrollbar {
      height: 7px;
      border-radius: 0;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 5px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  & a {
    width: auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #595858;

    & > span {
      padding-left: 0;
    }
  }
}

.ball {
  position: absolute;
  border-radius: 50px;
  width: 33px;
  height: 33px;
  top: 2px;
  left: 4px;
  transition: transform 0.3s ease;
  background-color: #ffffff;

  @media (max-width: 768px) {
    /* width: 90px;
    height: 45px;
    top: -3px;
    left: -1px; */
  }

  &.toggled {
    transform: translateX(63%);
  }
}

.checkboxWrapper {
  cursor: pointer;
  position: relative;
  border-radius: 50px;
  font-family: var(--font-familyLite);
  width: 64px;
  height: 39px;
  border: 1px solid #000000;
  background: rgb(0 0 0);

  @media (max-width: 768px) {
    /* height: 40px; */
  }
}

.bannerBox {
  position: relative;

  & .bannerImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background: linear-gradient(90deg, #0e0e0d 17.5%, rgba(217, 217, 217, 0) 79.77%); */
    z-index: 1;
    & > div {
      height: 100%;
      & img {
        height: 100%;
        border-radius: 4px;
      }
    }
  }
  & .bannerContent {
    height: 100%;
    width: 100%;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 43px;
    background: linear-gradient(90deg, #0e0e0d 17.5%, rgba(217, 217, 217, 0) 79.77%);
    @media (max-width: 920px) {
      padding: 40px 20px;
    }
    @media (max-width: 560px) {
      flex-direction: column;
      gap: 20px;
    }
  }
  & .bannerText {
    max-width: 496px;
    @media (max-width: 560px) {
      max-width: 100%;
      width: 100%;
      text-align: center;
    }
  }
  & .bannerTitle {
    color: var(--surface, #fff);
    font-family: Lora;
    font-size: 33px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 169.697% */
    margin: 0 0 10px 0;
    padding: 0;
  }
  & .bannerDescription {
    color: var(--surface, #fff);
    font-family: Martel;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.15px;
    margin: 0;
    padding: 0;
  }
}

.searchResultsTitle {
  color: #000;

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
  letter-spacing: 0.15px;
  border-top: 1px solid #e0e0e0;
  padding-top: 38px;
  margin-top: 50px;
}

.iconWrapper {
  position: relative;
  display: inline-block;
}

.hoverCard {
  visibility: hidden;
  width: 200px; /* Adjust width as needed */
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the card above the icon */
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: visibility 0.2s ease-in-out;
}

.iconWrapper:hover .hoverCard {
  visibility: visible;
}

.caret {
  /* Caret / arrow dimensions and position */
  width: 6px;
  height: 6px;
  position: absolute;
  bottom: -3px;
  left: 50%;
  margin-left: -3px;
  transform: rotate(45deg);

  /* Caret should have same bg-color and border as label */
  background-color: var(--matterColorLight);
  border-right-style: solid;
  border-right-color: var(--matterColorNegative);
  border-right-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--matterColorNegative);
  border-bottom-width: 1px;
}

.mapInsideToogleButton {
  position: absolute;
  top: 20px;
  right: 15px;
  z-index: 9;
}
